export default function NotFound() {
  return (
    <div className="mx-10 gap-10 flex flex-col justify-center items-center min-h-screen text-2xl">
      <h2 className="font-semibold text-red-700 font-poppins">ERROR 404</h2>
      <h2 className="font-semibold font-poppins">
        La página que buscas no existe o dejo de existir
      </h2>
      <h3>
        <p className="pb-2">Volver al comienzo</p>
        <a href="/tienda" className="underline">
          solidoherrajes.com
        </a>
      </h3>
    </div>
  );
}
