import * as React from 'react';
import Autoplay from 'embla-carousel-autoplay';

import { CardBanner, CardContentBanner } from '@/components/ui/cardBanner';
import {
  CarouselBanner,
  CarouselContentBanner,
  CarouselItemBanner,
  CarouselNextBanner,
  CarouselPreviousBanner,
} from '@/components/ui/carouselBanner';
import { PromotionalBanner } from '@/types';

interface PromotionalBannerCarouselProps {
  promotionalBanners: (PromotionalBanner & {
    largeImageUrls: string[];
    smallImageUrls: string[];
  })[];
}

export function PromotionalBannerCarousel({
  promotionalBanners,
}: PromotionalBannerCarouselProps) {
  const plugin = React.useRef(
    Autoplay({ delay: 5000, stopOnInteraction: true })
  );

  return (
    <CarouselBanner
      plugins={[plugin.current]}
      className="min-w-full h-auto"
      onMouseEnter={plugin.current.stop}
      onMouseLeave={plugin.current.reset}
      opts={{
        loop: true,
        align: 'start',
      }}
    >
      <CarouselContentBanner>
        {promotionalBanners.map((banner, id) => (
          <CarouselItemBanner key={id}>
            <CardBanner>
              <CardContentBanner className="flex flex-col items-center justify-center">
                <div className="hidden lg:block">
                  {banner.largeImageUrls.length > 0 ? (
                    <img
                      className="w-full h-full object-contain"
                      src={banner.largeImageUrls[0]}
                      alt={banner.attributes.name}
                    />
                  ) : (
                    <div className="w-64 h-64 bg-gray-200" />
                  )}
                </div>
                <div className="lg:hidden">
                  {banner.smallImageUrls.length > 0 ? (
                    <img
                      className="w-full h-full object-contain"
                      src={banner.smallImageUrls[0]}
                      alt={banner.attributes.name}
                      loading="lazy"
                    />
                  ) : (
                    <div className="w-64 h-64 bg-gray-200" />
                  )}
                </div>
              </CardContentBanner>
            </CardBanner>
          </CarouselItemBanner>
        ))}
      </CarouselContentBanner>
      <CarouselPreviousBanner />
      <CarouselNextBanner />
    </CarouselBanner>
  );
}
