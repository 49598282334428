import { generarEtiquetasSeoMeta } from '@/site-config';
import PromotionalBannerSlider from './promotional-banner-slider';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { FeaturedProduct, PromotionalBanner } from '@/types';
import {
  fetchPromotionalBanners,
  fetchFeaturedProducts,
  getImageUrls,
} from '@/backend/strapi/apiService';
import { PromotionalBannerCarousel } from './PromotionalBannerCarousel';
import { CarouselFeaturedProducts } from './CarouselFeaturedProducts';
import { CarouselItemsShowcase } from './CarouselItemsShowcase';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function StorePageLanding() {
  const [promotionalBanners, setPromotionalBanners] = useState<
    (PromotionalBanner & {
      largeImageUrls: string[];
      smallImageUrls: string[];
    })[]
  >([]);
  const [featuredProducts, setFeaturedProducts] = useState<FeaturedProduct[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  const products = featuredProducts.flatMap(
    (featuredProduct) => featuredProduct.attributes.products.data
  );

  const seoMeta = generarEtiquetasSeoMeta();

  useEffect(() => {
    const fetchPromotionalBannersData = async () => {
      try {
        const response = await fetchPromotionalBanners();
        // Fetch image URLs for each banner
        const bannersWithUrls = await Promise.all(
          response.map(async (banner: PromotionalBanner) => {
            const bannerImageUrls = banner.attributes.image.data || [];
            const bannerImageSmallUrls =
              banner.attributes.image_small.data || [];
            const largeImageUrls = await Promise.all(
              bannerImageUrls.map(async (img) => {
                const urls = await getImageUrls(img);
                return urls[0] || '';
              })
            );
            const smallImageUrls = await Promise.all(
              bannerImageSmallUrls.map(async (img) => {
                const urls = await getImageUrls(img);
                return urls[0] || '';
              })
            );

            return {
              ...banner,
              largeImageUrls,
              smallImageUrls,
            };
          })
        );
        setPromotionalBanners(bannersWithUrls);
      } catch (error) {
        console.error('Failed to fetch promotional banners:', error);
      }
    };

    const fetchFeaturedProductsData = async () => {
      try {
        const response = await fetchFeaturedProducts();
        setFeaturedProducts(response);
      } catch (error) {
        console.error('Failed to fetch featured products:', error);
      }
    };

    Promise.all([fetchPromotionalBannersData(), fetchFeaturedProductsData()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{seoMeta.titulo}</title>
          <meta name="description" content={seoMeta.descripcion} />
          <meta name="keywords" content={seoMeta.palabrasClave} />
          <meta name="author" content="Solido Herrajes" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://solidoherrajes.com/tienda" />
          <meta
            property="og:productos destacados"
            content={featuredProducts
              .flatMap((products) =>
                products.attributes.products.data.map(
                  (product) => product.attributes.name
                )
              )
              .join(', ')}
          />
        </Helmet>
        {loading ? (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
            <div className="col-span-2 md:col-span-3 lg:col-span-4">
              <Skeleton height={400} />
            </div>
            {Array.from({ length: 8 }).map((_, index) => (
              <div key={index} className="p-4 bg-white rounded shadow">
                <Skeleton height={250} />
                <Skeleton height={20} className="my-2" />
                <Skeleton height={20} className="my-2" />
              </div>
            ))}
          </div>
        ) : (
          <>
            {promotionalBanners.length !== 0 &&
              featuredProducts.length !== 0 && (
                <div>
                  <div className="flex flex-col justify-center items-center cursor-pointer">
                    <PromotionalBannerCarousel
                      promotionalBanners={promotionalBanners}
                    />
                  </div>
                  <div className="pt-2 rounded-lg overflow-hidden">
                    <PromotionalBannerSlider />
                  </div>
                  <div className="m-auto py-4">
                    <div className="flex flex-col">
                      {products.length !== 0 && (
                        <h2 className="flex justify-start py-3 text-xl">
                          Productos Destacados
                        </h2>
                      )}
                      <div className="cursor-pointer">
                        <CarouselFeaturedProducts products={products} />
                      </div>
                    </div>
                  </div>
                  <section>
                    <div className="flex w-full py-4">
                      <div className="hidden lg:flex flex-row gap-4">
                        <div>
                          <img
                            className="w-full"
                            src="/SANTANDER.png"
                            alt="Banner Promociones Santander"
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div className="lg:hidden flex flex-col gap-4 w-full">
                        <div>
                          <img
                            className="w-full"
                            src="/SANTANDER_chico.png"
                            alt="Banner Promociones Santander chico"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                  <section>
                    <div>
                      <CarouselItemsShowcase />
                    </div>
                  </section>
                  <section>
                    <h2 className="flex justify-start py-3 text-xl">Mi Casa</h2>
                    <div className="flex w-full py-5">
                      <div className="hidden lg:flex flex-row gap-4">
                        <div className="rounded-lg overflow-hidden relative">
                          <a href="/tienda/cocina">
                            <img
                              className="w-full transform transition-transform duration-300 hover:scale-110"
                              src="/categoria_cocina.png"
                              alt="banner cocina"
                              loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="rounded-lg overflow-hidden relative">
                          <a href="/tienda/hogar">
                            <img
                              className="w-full transform transition-transform duration-300 hover:scale-110"
                              src="/categoria_hogar.png"
                              alt="banner hogar"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      </div>

                      <div className="lg:hidden flex flex-col gap-4 w-full">
                        <div className="rounded-lg overflow-hidden relative">
                          <a href="/tienda/cocina">
                            <img
                              className="w-full transform transition-transform duration-300 hover:scale-110"
                              src="/categoria_cocina.png"
                              alt="banner pequeño cocina"
                              loading="lazy"
                            />
                          </a>
                        </div>
                        <div className="rounded-lg overflow-hidden relative">
                          <a href="/tienda/hogar">
                            <img
                              className="w-full transform transition-transform duration-300 hover:scale-110"
                              src="/categoria_hogar.png"
                              alt="banner pequeño hogar"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section>
                    <h2 className="flex justify-start py-2 text-xl">
                      Envios y Retiros
                    </h2>
                    <div className="flex w-full py-5">
                      <div className="hidden lg:flex flex-row gap-4">
                        <div>
                          <img
                            className="w-full"
                            src="/envios_banner.png"
                            alt="banner envios"
                            loading="lazy"
                          />
                        </div>
                        <div>
                          <img
                            className="w-full"
                            src="/retiro_banner.png"
                            alt="banner retiro"
                            loading="lazy"
                          />
                        </div>
                      </div>

                      <div className="lg:hidden flex flex-col gap-4 w-full">
                        <div>
                          <img
                            className="w-full"
                            src="/envios_banner.png"
                            alt="banner pequeño envios"
                            loading="lazy"
                          />
                        </div>
                        <div>
                          <img
                            className="w-full"
                            src="/retiro_banner.png"
                            alt="banner pequeño retiro"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              )}
          </>
        )}
      </HelmetProvider>
    </>
  );
}
