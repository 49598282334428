import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import BreadcrumbWithCustomSeparator from '@/components/ui/BreadcrumbWithCustomSeparator';
import Products from './Products';
import Filter from '@/components/Filter';
import {
  Product as ProductTypes,
  Filter as FilterTypes,
  FilterCategory,
} from '@/types';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationPrevious,
  PaginationNext,
} from '@/components/ui/pagination';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface BodyShopProps {
  selectedFilter: string;
  onFilterChange: (filter: string) => void;
  searchQuery: string;
  isLargeScreen: boolean;
  products: ProductTypes[];
  filterCategoriesFromSearch: FilterCategory[] | null;
  filtersFromSearch: FilterTypes[] | null;
  loading: boolean;
}

export default function BodyShop({
  onFilterChange,
  selectedFilter,
  searchQuery,
  isLargeScreen: largeScreen,
  products,
  filterCategoriesFromSearch,
  filtersFromSearch,
  loading,
}: BodyShopProps) {
  const location = useLocation();
  const [breadcrumbItems, setBreadcrumbItems] = useState<
    { title: string; path: string; isCurrentPage: boolean }[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20;
  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const pathSegments = location.pathname
      .split('/')
      .filter((segment) => segment);

    const items = pathSegments.map((segment, index) => {
      const path = '/' + pathSegments.slice(0, index + 1).join('/');
      const title = segment
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      return {
        title,
        path,
        isCurrentPage: index === pathSegments.length - 1,
      };
    });

    setBreadcrumbItems(items);
  }, [location.pathname]);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const totalPages = Math.ceil(products.length / productsPerPage);

  // Effect for selectedFilter so if a filter is selected, the page resets to 1
  useEffect(() => {
    setCurrentPage(1);
  }, [onFilterChange]);

  const handleFilterChange = (newFilter: string) => {
    onFilterChange(newFilter); // This will be called with empty string when clearing
    setCurrentPage(1); // Reset to first page when filter changes
  };

  return (
    <div className="flex" ref={topRef}>
      {largeScreen && (
        <Filter
          filter={false}
          handleSetFilter={() => {}}
          isLargeScreen={largeScreen}
          onFilterChange={handleFilterChange}
          filtersCategoriesFromSearch={filterCategoriesFromSearch || undefined}
          filtersFromSearch={filtersFromSearch || undefined}
          products={products}
          selectedFilter={selectedFilter}
          loading={loading}
        />
      )}
      <div className="bg-gray-100 w-full mx-auto p-4 b-16 sm:pl-5">
        <BreadcrumbWithCustomSeparator items={breadcrumbItems} />
        {loading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {Array.from({ length: 16 }).map((_, index) => (
              <div key={index} className="p-4 bg-white rounded shadow mt-5">
                <Skeleton height={200} />
                <Skeleton height={20} className="my-2" />
                <Skeleton height={20} className="my-2" />
              </div>
            ))}
          </div>
        ) : (
          <>
            {searchQuery && products.length > 0 ? (
              <h1 className="text-xl font-poppins font-semibold py-4 text-gray-500">
                Resultado de busqueda para: {searchQuery}
              </h1>
            ) : searchQuery && products.length === 0 ? (
              <h1 className="text-xl font-poppins font-semibold py-4 text-gray-500">
                No se encontraron resultados para: {searchQuery}
              </h1>
            ) : null}
            <Products products={currentProducts} />
          </>
        )}
        <Pagination className="pagination-controls">
          <PaginationPrevious
            isDisabled={currentPage === 1}
            onClick={() => {
              if (currentPage !== 1) {
                handlePageChange(currentPage - 1);
              }
            }}
          />
          <PaginationContent>
            {Array.from({ length: totalPages }, (_, index) => (
              <PaginationItem key={index}>
                <PaginationLink
                  isActive={currentPage === index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className="cursor-pointer"
                >
                  {index + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
          </PaginationContent>
          <PaginationNext
            isDisabled={currentPage === totalPages}
            onClick={() => {
              if (currentPage !== totalPages) {
                handlePageChange(currentPage + 1);
              }
            }}
          />
        </Pagination>
      </div>
    </div>
  );
}
