import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Filter, FilterCategory, Product } from '@/types';

export function useFilter(
  products: Product[],
  filtersFromSearch?: Filter[],
  filtersCategoriesFromSearch?: FilterCategory[],
  initialSelectedFilter?: string // Add this parameter
) {
  const [filters, setFilters] = useState<Filter[]>([]);
  const [filterCategories, setFilterCategories] = useState<FilterCategory[]>(
    []
  );
  const [selectedFilters, setSelectedFilters] = useState<Set<string>>(
    new Set(initialSelectedFilter ? [initialSelectedFilter] : [])
  );
  const location = useLocation();

  // Keep the selected filters when initialSelectedFilter changes
  useEffect(() => {
    if (initialSelectedFilter) {
      setSelectedFilters(new Set([initialSelectedFilter]));
    }
  }, [initialSelectedFilter]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('q');

    if (searchQuery && filtersFromSearch && filtersCategoriesFromSearch) {
      setFilters(filtersFromSearch);
      setFilterCategories(filtersCategoriesFromSearch);
    } else if (!searchQuery && products.length > 0) {
      const uniqueFilters = new Map<number, Filter>();
      const uniqueCategories = new Map<number, FilterCategory>();

      products.forEach((product) => {
        product.attributes.filters?.data?.forEach((filter: Filter) => {
          uniqueFilters.set(filter.id, filter);
        });
        product.attributes.filter_categories?.data?.forEach(
          (category: FilterCategory) => {
            uniqueCategories.set(category.id, category);
          }
        );
      });

      setFilters(Array.from(uniqueFilters.values()));
      setFilterCategories(Array.from(uniqueCategories.values()));
    }
  }, [
    location.search,
    filtersFromSearch,
    filtersCategoriesFromSearch,
    products,
  ]);

  const toggleFilter = (filterName: string) => {
    setSelectedFilters((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(filterName)) {
        newSet.delete(filterName);
      } else {
        newSet.add(filterName);
      }
      return newSet;
    });
  };

  const clearFilters = () => {
    setSelectedFilters(new Set());
    return ''; // Return empty string to reset filter state
  };

  // Add active filter value getter
  const getActiveFilter = () => {
    return Array.from(selectedFilters)[0] || '';
  };

  return {
    filters,
    filterCategories,
    selectedFilters,
    toggleFilter,
    clearFilters,
    getActiveFilter,
  };
}
