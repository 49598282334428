import { useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { SVGProps } from 'react';
import { JSX } from 'react/jsx-runtime';
import CloseIcon from './ui/CloseIcon';
import {
  Filter as FilterTypes,
  FilterCategory as FilterCategoryTypes,
  Product as ProductTypes,
} from '@/types';
import { matchPath } from 'react-router-dom';
import { useFilter } from '@/hooks/useFilter';

interface FilterProps {
  filter: boolean;
  handleSetFilter: () => void;
  isLargeScreen: boolean;
  onFilterChange: (filter: string) => void;
  filtersFromSearch?: FilterTypes[];
  filtersCategoriesFromSearch?: FilterCategoryTypes[];
  products: ProductTypes[];
  selectedFilter?: string;
  loading?: boolean;
}

export default function Filter({
  filter,
  handleSetFilter,
  isLargeScreen,
  onFilterChange,
  filtersFromSearch,
  filtersCategoriesFromSearch,
  products,
  selectedFilter,
  loading,
}: FilterProps) {
  const {
    filters,
    filterCategories,
    selectedFilters,
    toggleFilter,
    clearFilters,
  } = useFilter(
    products,
    filtersFromSearch,
    filtersCategoriesFromSearch,
    selectedFilter
  );
  const location = useLocation();

  // Don't render anything if loading and no filters are selected
  if (loading && !selectedFilter) {
    return null;
  }

  const handleFilterChange = (value: string) => {
    toggleFilter(value);
    onFilterChange(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!isLargeScreen) {
      handleSetFilter();
    }
  };

  const handleClearFilters = () => {
    const resetValue = clearFilters();
    onFilterChange(resetValue);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (
    location.pathname === '/tienda' ||
    matchPath('/tienda/producto/*', location.pathname)
  ) {
    return null;
  }

  const renderFilterSection = (
    <div className="flex flex-col gap-3">
      {filterCategories.map((filterCategory) => (
        <div key={filterCategory.id}>
          <h4 className="text-lg font-semibold py-3">
            {filterCategory.attributes.name}
          </h4>
          <div className="flex flex-col gap-2">
            {filters
              .filter(
                (filter) =>
                  filter.attributes.filter_category?.data?.id ===
                  filterCategory.id
              )
              .map((filter) => (
                <div key={filter.id} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id={filter.attributes.name}
                    checked={selectedFilters.has(filter.attributes.name)}
                    onChange={() => handleFilterChange(filter.attributes.name)}
                    className="rounded border-gray-300"
                  />
                  <Label htmlFor={filter.attributes.name} className="ml-2">
                    {filter.attributes.name}
                  </Label>
                </div>
              ))}
          </div>
        </div>
      ))}
      {selectedFilters.size > 0 && (
        <Button onClick={handleClearFilters} variant="outline" className="mt-4">
          Limpiar filtros
        </Button>
      )}
    </div>
  );

  return (
    <div className={isLargeScreen ? 'flex bg-gray-100' : ''}>
      {!isLargeScreen ? (
        <div className="p-2 lg:hidden">
          <Button
            onClick={handleSetFilter}
            variant="outline"
            className="flex items-center gap-2 px-4 py-2 rounded-md"
          >
            <FilterIcon className="w-5 h-5" />
            <span>Filtrar ({selectedFilters.size})</span>
          </Button>
          {filter && (
            <div className="fixed inset-0 bg-white z-50 overflow-y-auto">
              <div className="p-4 mt-[10vh]">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">Filtros</h2>
                  <CloseIcon onClick={handleSetFilter} />
                </div>
                {renderFilterSection}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="hidden lg:block p-6 border-r min-w-[250px]">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">
            Filtrar Búsqueda
          </h2>
          {renderFilterSection}
        </div>
      )}
    </div>
  );
}

function FilterIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
    </svg>
  );
}
