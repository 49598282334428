import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuList,
  NavigationMenuContent,
} from '@/components/ui/navigation-menu';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { List } from '@geist-ui/icons';
import CloseIcon from './ui/CloseIcon';
import Filter from './Filter';
import { Button } from '@/components/ui/button';
import SearchBar from './SearchBar';
import SearchIcon from './ui/SearchIcon';
import { fetchProductsBySearch } from '@/utils/SearchUtils';
import {
  Filter as FilterTypes,
  FilterCategory as FilterCategoryTypes,
  Product as ProductTypes,
} from '@/types';

const categories = [
  {
    name: 'Cocina',
    subcategories: [
      {
        name: 'Electrococina',
        items: [
          'Anafes',
          'Campanas',
          'Hornos',
          'Microondas',
          'Heladeras',
          'Cafeteras',
          'Vinotecas',
          'Accesorios',
          'Bandejas',
        ],
      },
    ],
  },
  {
    name: 'Hogar',
    subcategories: [
      {
        name: 'Electrohogar',
        items: ['Lavavajillas', 'Lavasecarropas'],
      },
    ],
  },
  {
    name: 'Vestidor',
    subcategories: [
      {
        name: 'Corredizos',
        items: ['Kits de placard'],
      },
    ],
  },
  {
    name: 'Cajones',
    subcategories: [
      {
        name: 'Matrix Box',
        items: ['Matrix Box S'],
      },
      {
        name: 'Correderas Telescopicas',
        items: [
          'Correderas telescopicas livianas',
          'Correderas telescopicas pesadas',
          'Correderas telescopicas cierre suave',
        ],
      },
    ],
  },
  {
    name: 'Adhesivos',
    subcategories: [
      {
        name: 'Poliuretanicos',
        items: [
          'Cola poliuretanica',
          'Espuma poliuretanica',
          'Cola vinilica',
          'Cemento de contacto',
        ],
      },
      {
        name: 'Siliconas',
        items: ['Siliconas aceticas', 'Siliconas neutras'],
      },
    ],
  },
  {
    name: 'Muebles',
    subcategories: [
      {
        name: 'Bisagra Cazoleta',
        items: [
          'Bisagra cazoleta cierre comun',
          'Bisagra cazoleta cierre suave',
          'Bisagra cazoleta push open',
        ],
      },
    ],
  },
  {
    name: 'Puertas',
    subcategories: [
      {
        name: 'Bisagras',
        items: ['Bisagra acero inoxidable'],
      },
      {
        name: 'Manijas',
        items: ['Manija acero inoxidable doble balancin'],
      },
      {
        name: 'Cerraduras',
        items: [
          'Cerraduras de interior',
          'Cerradura doble paleta pasador rectangular',
        ],
      },
      {
        name: 'Cerraduras electronicas',
        items: ['Cerraduras ihub smartlocks'],
      },
    ],
  },
];

interface HeaderShopProps {
  onFilterChange: (filter: string) => void;
  selectedFilter: string;
  onSearch: (query: string) => void;
  searchQuery: string;
  isLargeScreen: boolean;
  products: ProductTypes[];
  filterCategoriesFromSearch: FilterCategoryTypes[] | null;
  filtersFromSearch: FilterTypes[] | null;
}

export default function HeaderShop({
  onFilterChange,
  onSearch,
  searchQuery,
  isLargeScreen: largeScreen,
  products,
}: HeaderShopProps) {
  const [searchBar, setSearchBar] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filtersFromSearch, setFiltersFromSearch] = useState<
    FilterTypes[] | null
  >(null);
  const [filtersCategoriesFromSearch, setFilterCategoriesFromSearch] = useState<
    FilterCategoryTypes[] | null
  >(null);
  const location = useLocation();
  const categoryMatch = location.pathname.match(/\/tienda\/(.+)/);
  const category = categoryMatch
    ? categoryMatch[1].replace(/%20/g, '-').toLowerCase()
    : null;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        if (searchQuery) {
          const searchData = await fetchProductsBySearch(searchQuery);
          setFiltersFromSearch(searchData.filters);
          setFilterCategoriesFromSearch(searchData.filterCategories);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchProducts();
  }, [searchQuery]);

  function handleSearchBar() {
    setSearchBar(!searchBar);
    if (filter) setFilter(false);
  }

  function handleSetFilter() {
    setFilter(!filter);
    if (searchBar) setSearchBar(false);
  }

  function refactorSubcategory(subcategory: string) {
    return subcategory.replace(/ /g, '-').toLowerCase();
  }

  function refactorItem(item: string) {
    return item.replace(/ /g, '-').toLowerCase();
  }

  return (
    <header className="flex h-16 w-full items-center bg-background px-4 md:px-32 sticky z-10 top-0">
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="lg:hidden">
            <List className="h-5 w-5" />
            <span className="sr-only">Toggle menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="overflow-auto max-h-screen">
          <div className="grid gap-2 py-6">
            <Accordion type="single" collapsible>
              {categories.map((category) => (
                <AccordionItem
                  key={category.name}
                  value={category.name.toLowerCase()}
                  className="text-xl"
                >
                  <AccordionTrigger>{category.name}</AccordionTrigger>
                  <AccordionContent>
                    {category.subcategories &&
                      category.subcategories.map((subcategory) => (
                        <div key={subcategory.name} className="ml-4">
                          <h2 className="font-semibold text-lg mt-2 mb-2">
                            {subcategory.name}
                          </h2>
                          {subcategory.items.map((item) => (
                            <a
                              key={item}
                              href={`/tienda/${category.name.toLowerCase()}/${refactorSubcategory(subcategory.name)}/${refactorItem(item)}`}
                              className="flex p-2 pl-6 text-base"
                            >
                              {item}
                            </a>
                          ))}
                        </div>
                      ))}
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </SheetContent>
      </Sheet>
      <div className="hidden lg:flex">
        <NavigationMenu>
          <NavigationMenuList>
            {categories.map((category) => (
              <NavigationMenuItem key={category.name}>
                <NavigationMenuTrigger className="group inline-flex h-9 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-base font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50">
                  {category.name}
                </NavigationMenuTrigger>
                {category.subcategories && (
                  <NavigationMenuContent className="flex">
                    <ul className="px-8 mb-4">
                      {category.subcategories.map((subcategory) => (
                        <li key={subcategory.name}>
                          <h4 className="font-semibold text-lg py-3 whitespace-nowrap">
                            {subcategory.name}
                          </h4>
                          {subcategory.items.map((item) => (
                            <a
                              key={item}
                              href={`/tienda/${category.name.toLowerCase()}/${refactorSubcategory(subcategory.name)}/${refactorItem(item)}`}
                              className="flex p-1 text-base whitespace-nowrap hover:bg-red-400 hover:rounded"
                            >
                              {item}
                            </a>
                          ))}
                        </li>
                      ))}
                    </ul>
                    {category.name === 'Cocina' && (
                      <img
                        src="/bosch-cocina.jpeg"
                        alt="bosch cocina"
                        className="object-cover"
                      />
                    )}
                    {category.name === 'Hogar' && (
                      <img
                        src="/bosch-hogar.jpeg"
                        alt="bosch hogar"
                        className="object-cover"
                      />
                    )}
                    {category.name === 'Vestidor' && (
                      <img
                        src="/vestidor.jpeg"
                        alt="vestidor"
                        className="object-cover"
                      />
                    )}
                    {category.name === 'Cajones' && (
                      <img
                        src="/cajones.jpeg"
                        alt="cajones"
                        className="object-cover"
                      />
                    )}
                    {category.name === 'Adhesivos' && (
                      <img
                        src="/adhesivos.jpeg"
                        alt="adhesivos"
                        className="object-cover"
                      />
                    )}
                    {category.name === 'Muebles' && (
                      <img
                        src="/muebles.jpeg"
                        alt="muebles"
                        className="object-cover"
                      />
                    )}
                    {category.name === 'Puertas' && (
                      <img
                        src="/puertas.jpeg"
                        alt="puertas"
                        className="object-cover"
                      />
                    )}
                  </NavigationMenuContent>
                )}
              </NavigationMenuItem>
            ))}
          </NavigationMenuList>
        </NavigationMenu>
      </div>
      {category && !largeScreen && (
        <Filter
          filter={filter}
          handleSetFilter={handleSetFilter}
          isLargeScreen={largeScreen}
          onFilterChange={onFilterChange}
          filtersFromSearch={filtersFromSearch || undefined}
          filtersCategoriesFromSearch={filtersCategoriesFromSearch || undefined}
          products={products}
        />
      )}
      <div className="flex items-center gap-2 ml-auto">
        <Button onClick={handleSearchBar} variant="outline" size="icon">
          {searchBar ? (
            <CloseIcon className="h-5 w-5" />
          ) : (
            <SearchIcon className="h-5 w-5 flex" />
          )}
          <span className="sr-only">{searchBar ? 'Close' : 'Search'}</span>
        </Button>
        {searchBar && (
          <SearchBar onSearch={onSearch} searchQuery={searchQuery} />
        )}
      </div>
    </header>
  );
}
