import { SVGProps } from 'react';
import { JSX } from 'react/jsx-runtime';

export default function Footer() {
  return (
    <footer id="footer" className="bg-muted pt-6 pb-4 font-merriweather">
      <div className="container max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-10">
        <div>
          <img
            src="/SH.svg"
            alt="Solido Herrajes Logo"
            className="w-12 h-auto"
          />
          <p className="text-sm pt-4 text-muted-foreground text-wrap">
            Somos Solido Herrajes, nuestro rol en la industria de la
            construcción es brindar todos los artículos que sean funcionales de
            alto rendimiento, así como también de terminación, siendo también
            capaces de proveer al sector de amoblamientos, herreria, cerrajeria
            realizando ventas por mayor y menor.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Enlaces</h3>
          <hr className="border-gray-400" />
          <ul className="text-muted-foreground space-y-2 ml-3 list-disc underline mt-4">
            <li>
              <a href="https://www.solidoherrajes.com/casa">
                Acerca de Nosotros
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.google.com/maps?ll=-34.390052,-58.741003&z=14&t=m&hl=en&gl=US&mapclient=embed&q=Maip%C3%BA+1599+B1623CFR+Ingeniero+Maschwitz+Provincia+de+Buenos+Aires"
              >
                Ubicación
              </a>
            </li>
            <li>
              <a target="_blank" href="https://wa.me/5491128600745">
                Contacto
              </a>
            </li>
            <li>
              <a href="https://www.solidoherrajes.com/tienda/cocina">
                Catálogo Cocina
              </a>
            </li>
            <li>
              <a href="https://www.solidoherrajes.com/tienda/hogar">
                Catálogo Hogar
              </a>
            </li>
            <li>
              <a href="http://www.solidoherrajes.com/tienda/vestidor">
                Catálogo Vestidor
              </a>
            </li>
            <li>
              <a href="http://www.solidoherrajes.com/tienda/cajones">
                Catálogo Cajones
              </a>
            </li>
            <li>
              <a href="http://www.solidoherrajes.com/tienda/adhesivos">
                Catálogo Adhesivos
              </a>
            </li>
            <li>
              <a href="http://www.solidoherrajes.com/tienda/muebles">
                Catálogo Muebles
              </a>
            </li>
            <li>
              <a href="http://www.solidoherrajes.com/tienda/puertas">
                Catálogo Puertas
              </a>
            </li>
          </ul>
        </div>
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Contáctanos</h3>
          <hr className="border-gray-400" />
          <div className="space-y-2 text-muted-foreground underline flex flex-col flex-wrap">
            <div className="flex items-center gap-2">
              <PhoneIcon className="w-5 h-5" />
              <a
                href="https://wa.me/5491128600745"
                target="_blank"
                rel="noopener noreferrer"
              >
                +54 11 2860-0745
              </a>
            </div>
            <div className="flex items-center gap-2">
              <InstagramIcon className="w-5 h-5" />
              <a
                href="https://www.instagram.com/solidoherrajes/?hl=es-la"
                target="_blank"
                rel="noopener noreferrer"
              >
                @solidoherrajes
              </a>
            </div>
            <div className="flex items-center gap-2">
              <MailIcon className="w-5 h-5" />
              <a
                target="_blank"
                href="mailto:info@solidoherrajes.com"
                rel="noopener noreferrer"
              >
                info@solidoherrajes.com
              </a>
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Horarios</h3>
          <hr className="border-gray-400" />
          <div className="space-y-2 text-muted-foreground">
            <div>Lunes - Viernes: 8:00 - 13:00, 14:30 - 18:00</div>
            <div>Sábados: 9:00 - 13:00</div>
            <div>Domingos: Cerrado</div>
          </div>
        </div>
        <div className="space-y-4 md:col-span-2 lg:col-span-1">
          <h3 className="text-lg font-semibold">Visítanos</h3>
          <hr className="border-gray-400" />
          <div className="text-muted-foreground">
            <div className="mt-4">
              <iframe
                id="googleMapsIframe"
                src="https://maps.google.com/maps?width=100%25&height=200&hl=en&q=Maip%C3%BA%201599,%20B1623CFR%20Ingeniero%20Maschwitz,%20Provincia%20de%20Buenos%20Aires+(Herrajes%20Solido)&t=&z=14&ie=UTF8&iwloc=B&amp;output=embed"
                width="100%"
                height="200"
                style={{ border: 0 }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

function InstagramIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
      <line x1="17.5" x2="17.51" y1="6.5" y2="6.5" />
    </svg>
  );
}

function MailIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="16" x="2" y="4" rx="2" />
      <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
    </svg>
  );
}

function PhoneIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
    </svg>
  );
}
