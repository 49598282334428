import { useEffect } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import Home from './components/Home';
import Store from './pages/store/StorePage';
import Layout from './components/Layout';
import NotFound from './components/404';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Navigate to="/tienda" replace />} />
            <Route path="/casa" element={<Home />} />
            <Route path="/tienda/*" element={<Store />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </Router>
    </HelmetProvider>
  );
}

export default App;
